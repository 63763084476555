#brand-logo {
	.section-small {
		margin-top: -90px;
	}
}

.brand-logo {
	text-align: center;

	img {
		opacity: 0.5;
		transition: all 0.3s ease-in-out;

		&:hover {
			opacity: 1;
		}
	}
}

#brand-logo-two {
	padding-top: 350px;

	@media(max-width: 991px) {
		padding-top: 180px;
	}

	@media(max-width: 576px) {
		padding-top: 80px;
	}
}

.brand-logo-ab {
	padding-bottom: 120px;
}

.logo-carousel {
	.swiper-wrapper {
		display: flex;
		align-items: center;
	}
}


.brand-logo-two {
	height: 100px;
	width: 100px;
	min-width: 100px;
	min-height: 100px;
	background: #fff;
	box-shadow: 0px 2px 4px 0px rgba(14, 3, 52, 0.06);
	border-radius: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.logo-carousel-travel {
	.swiper-wrapper {
		align-items: center;
	}

	.brand-logo {
		img {
			opacity: 1;
		}
	}
}



/**
Logo List
 */
.client-logo-list {
	padding-bottom: 65px;
}

.logo-list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	li {
		width: 20%;
		margin-bottom: 82px;

		@media(max-width: 991px) {
			width: 33.3333%;
		}

		@media(max-width: 576px) {
			width: 50%;
		}

		a {
			display: block;
		}
	}
}

@media (max-width: 991px) {
	#brand-logo {
		padding-top: 76px;

		.section-small {
			margin-top: 0;

			.title {
				margin-bottom: 50px;
			}
		}

	}

	.brand-logo-ab {
		padding-bottom: 75px;

		.section-small .title {
			margin-bottom: 60px;
		}
	}
}

@media (max-width: 768px) {
	#brand-logo {
		padding-top: 30px;

		.section-small {
			margin-top: 0;
		}
	}

}



@media (max-width: 480px) {
	#brand-logo {
		padding-top: 0;

		.section-small {
			margin-top: 0;
		}
	}

}



.client-logo {
	padding: 50px 0;
	background: #fcfafb;

	@media(max-width: 991px) {
		padding: 100px 0 0;
	}

	.app-client-logo {
		max-width: 970px;
	}

	.logo {
		@media (max-width:991px) {
			margin-bottom: 40px;
			text-align: center;
		}
	}
}





.partners {
	.pl-95 {
		padding-left: 80px;

		@media (max-width:991px) {
			padding-left: 20px;
		}
	}

	.section-heading {
		margin-bottom: 84px;
		margin-top: 14px;
		margin-bottom: 70px;

		@media(max-width:991px) {
			margin-top: 60px;
			margin-bottom: 0;
			text-align: center !important;
		}

		.sub-title {
			font-size: 14px;
			font-weight: 500;
			color: #d43396;
			margin-bottom: 12px;
			text-transform: uppercase;
		}

		.section-title {
			font-size: 40px;
			line-height: 50px;
			margin-bottom: 0;
		}

		.app-section-heading {
			margin-bottom: 34px;
		}

		p {
			margin-bottom: 52px;

			@media(max-width:767px) {
				br {
					display: none;
				}
			}
		}

		.pix-btn {
			border-radius: 30px;
			overflow: hidden;
			border: 2px solid $color__theme-9;
			background-color: $color__theme-9;
			box-shadow: 0px 20px 30px 0px rgba(146, 18, 97, 0.2);
			padding: 15px 29px;
        	line-height: 1.2;

			&:hover {
				background-color: transparent;
				color: $color__theme-9;
				box-shadow: none;
			}
		}
	}

	.client-logo-wrapper {
		.client-logo-items {
			width: initial;
			height: 560px;

			.logo-item {
				background-color: #fff;
				box-shadow: 0px 30px 70px 0px rgba(75, 3, 47, 0.08);
				position: absolute;
				list-style: none;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.logo-item:nth-child(1) {
				height: 160px;
				width: 160px;
				border-radius: 50%;
				left: 70px;
				top: 35px;

				@media (max-width:575px) {
					left: 40px;
					width: 125px;
					height: 125px;
				}
			}

			.logo-item:nth-child(2) {
				height: 120px;
				width: 120px;
				border-radius: 50%;
				left: 302px;
				top: 55px;

				@media (max-width:575px) {
					left: 240px;
				}

				@media (max-width:400px) {
					left: 220px;
				}
			}

			.logo-item:nth-child(3) {
				height: 150px;
				width: 150px;
				border-radius: 50%;
				left: 172px;
				top: 236px;

				@media (max-width:575px) {
					left: 38px;
					width: 120px;
					height: 120px;
					top: 200px;
				}

				@media (max-width:400px) {
					left: 42px;
				}
			}

			.logo-item:nth-child(4) {
				height: 170px;
				width: 170px;
				border-radius: 50%;
				left: 372px;
				top: 196px;

				@media (max-width:575px) {
					left: 240px;
					height: 130px;
					width: 130px
				}

				@media (max-width:400px) {
					left: 200px;
				}
			}

			.logo-item:nth-child(5) {
				height: 100px;
				width: 100px;
				border-radius: 50%;
				left: 70px;
				top: 386px;

				@media (max-width:575px) {
					left: 40px;
					top: 360px;
				}
			}

			.logo-item:nth-child(6) {
				height: 120px;
				width: 120px;
				border-radius: 50%;
				left: 242px;
				top: 456px;

				@media (max-width:575px) {
					left: 135px;
				}

				@media (max-width:400px) {
					top: 465px;
				}
			}

			.logo-item:nth-child(7) {
				height: 160px;
				width: 160px;
				border-radius: 50%;
				left: 412px;
				top: 436px;

				@media (max-width:575px) {
					height: 130px;
					width: 130px;
					left: 240px;
					top: 342px;
				}

				@media (max-width:400px) {
					left: 220px;
					top: 340px;
				}
			}
		}
	}
}
